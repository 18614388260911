import axios from 'axios';

axios.defaults.headers.common['Accept'] = 'application/json' // for all requests
axios.defaults.validateStatus = false;

//if(window.localStorage.getItem('user-token')){
//axios.defaults.headers.common['Authorization'] = 'Bearer '+window.localStorage.getItem('user-token');
//}

const second_server = "https://inscricoesapi.tkx.com.br/"+ 'api/';
const api_url = process.env.VUE_APP_ROOT_API;

export default {
    name: 'User',
    registration(user) {
        var date = user.birthday.split('/')
        user.birthday = date[2] + '-' + date[1] + '-' + date[0];
        return axios.post(api_url + 'api/signup', user);
    },
    login(email, senha) {
        let Auth = {
            "grant_type": "password",
            "client_id": 2,
            "client_secret": process.env.VUE_APP_ACCESS_KEY,
            "username": email,
            "password": senha,
            "scope": ""
        };
        return axios.post(api_url + 'oauth/token', Auth);
    },
    reset_password(user) {
        return axios.post(api_url + 'api/reset-password', { email: user });
    },
    profile(token) {
        return axios.get(api_url + 'api/auth/profile', { headers: { 'Authorization': 'Bearer ' + token } });
    },
    get_events(token){
        return axios.get(api_url + 'api/auth/minha-conta', { headers: { 'Authorization': 'Bearer ' + token } });
    },
    update_profile(user,token){
        return axios.post(api_url + 'api/auth/update-profile', user , { headers: { 'Authorization': 'Bearer ' + token } });
    },
    get_userid(email,token){
        return axios.post(api_url + 'api/auth/email-get-user', {"email": email} , { headers: { 'Authorization': 'Bearer ' + token } });
    },
    get_user_event(event_id,user_id,token){
        return axios.post(second_server + 'api/auth/inscrito', {"client_id": user_id,'event_id':event_id} , { headers: { 'Authorization': 'Bearer ' + token } });
    }
}
